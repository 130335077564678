import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m not a dog-person they said.`}<br parentName="p"></br>{`
`}{`And then we see them out of bed,`}<br parentName="p"></br>{`
`}{`At sunrise, 7 in the morning.`}</p>
    <p>{`Ew, dogs are gross!`}<br parentName="p"></br>{`
`}{`And then you share your piece of toast,`}<br parentName="p"></br>{`
`}{`With younger ones.`}</p>
    <p>{`Oh mother, can we get a dog?`}<br parentName="p"></br>{`
`}{`We’ve already had this dialog`}<br parentName="p"></br>{`
`}{`A million times.`}</p>
    <p>{`A million jobs and chores ‘ve been done`}<br parentName="p"></br>{`
`}{`To get this little one:`}<br parentName="p"></br>{`
`}{`A place that he’ll consider home`}<br parentName="p"></br>{`
`}{`An owner of his own`}<br parentName="p"></br>{`
`}{`And finally- a fresh new bone.`}</p>
    <p>{`Over the years we get attached`}<br parentName="p"></br>{`
`}{`To someone small,`}<br parentName="p"></br>{`
`}{`Who shows their love,`}<br parentName="p"></br>{`
`}{`Without using a single word-`}<br parentName="p"></br>{`
`}{`That’s how a miracle occurred.`}</p>
    <p>{`They’ll stay with us until they’ll die`}<br parentName="p"></br>{`
`}{`Watching us as we lie,`}<br parentName="p"></br>{`
`}{`With eyes filled up with pain,`}<br parentName="p"></br>{`
`}{`Knowing that we’ll take the blame,`}<br parentName="p"></br>{`
`}{`Upon ourselves.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      